/* InstaNot */

/* default settings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: white;
  color: rgb(12, 12, 12);
}
.nav,
.hero-section {
  background-color: black;
}

/* reuseable design start */
.container {
  width: 80%;
  margin: 0 auto;
}
/* reuseable design end */

/* All Navbar start */
.nav-contents {
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0;
  align-items: center;
}

.menus ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menus li {
  list-style: none;
  padding-left: 40px;
}

.menus a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-weight: 600;
}

.menus a:hover {
  border-bottom: 1px solid orange;
}
/* All Navbar end */

/* All Homepage start */
.hero-box {
  color: white;
  padding: 120px 0;
  background-image: url("../images/Home_Asset.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: right;
}

.home-title {
  width: 30ch;
  letter-spacing: 1.5px;
  line-height: 1.4;
  font-weight: 600;
}

.home-desc {
  padding-top: 20px;
}

.home-main-title {
  margin: 100px 0 50px;
  text-align: center;
}

.home-main-desc {
  text-align: center;
  width: 55%;
  margin: 0 auto 100px;
  padding: 10px 0;
  line-height: 1.5;
  border-top: 2px solid seagreen;
  border-bottom: 2px solid seagreen;
}

.home-note-color {
  background-color: lightcyan;
  font-weight: 600;
  border-radius: 8px;
}

.home-main-desc-sub {
  margin-top: 10px;
  text-align: center;
}

.hero-box Button:hover {
  background-color: #ffa600f1;
}
/* All Homepage end */

/* All Login page start */
.login-form {
  display: flex;
  flex-wrap: wrap;
}

.login-form a {
  text-decoration: none;
  color: blue;
  cursor: pointer;
}

.login-welcome {
  margin-bottom: 40px;
  line-height: 2;
}

.sub-login {
  margin: 50px auto 100px;
  width: fit-content;
  line-height: 1.5;
}

.login-label {
  font-weight: 600;
}

.sub-login-input {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  border: 2px solid lightgrey;
  outline: none;
}

.sub-login-input:focus {
  border: none;
  outline: 2px solid lightseagreen;
}

.login-settings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.login-settings a {
  margin-left: 2rem;
}

.login-option {
  padding: 0 50px;
}

.login-option hr {
  transform: rotate(90deg);
  margin: 25px 0;
}

.login-social-button {
  display: flex;
}
/* All Login page end */

/* writenote page starts here */
/* Container for the page */
.writeNote-container {
  width: 400px;
  height: 80vh;
  margin: 35px auto;
  padding: 20px;
  background: linear-gradient(to bottom, #bfc2eb, #d4d4e3);
  border: 1px solid #c4b1b1;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.writeNote-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.writeNote-container label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.writeNote-container input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.writeNote-container textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
}

/* Styling edit note page */
.edit-note {
  width: 400px;
  margin: 20px auto;
  padding: 20px;
  background: linear-gradient(to bottom, #bfc2eb, #d4d4e3);
  background-size: cover;
  background-position: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333;
}

.edit-note h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.edit-note label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #fff;
}

.edit-note input [type="text"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

/* View Note  */
.view-main-note {
  width: fit-content;
  margin: 10px auto;
  padding: 2rem;
}

.note-date {
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  padding-bottom: 1rem;
}

.note-body {
  margin-top: 2rem;
}

/*sign-up form */

.registration-form {
  max-width: 400px;
  margin: 0 auto 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #3e3b3b;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.registration-form h2 {
  margin-bottom: 20px;
  color: #eeebeb;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #f7e0e0;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group input[type="password"]:focus {
  outline: none;
  border-color: #275b44;
}

button[type="submit"] {
  background-color: #ffa600f1;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #d0e9ae;
}

.signup-option {
  margin-bottom: 10px;
  color: #fff;
}

.signup-option a {
  text-decoration: none;
  color: #ffa600f1;
  cursor: pointer;
}

/* All Dashboard start */
.notes-unavailable {
  min-height: 75vh;
}

.dashboard-notice {
  margin: 50px auto 100px;
}

.dashboard-button {
  margin: 50px auto;
}

.notes-unavailable a {
  text-decoration: none;
}
/* All Dashboard end */

/* All Footer start */
footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

/* Style for the text inside the footer */
.footer-content {
  font-size: 16px;
}
/* All Footer end */

/* Responsiveness  using Media Query */
@media (max-width: 1024px) {
  .hero-box {
    background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.25)),
      url("../images/Home_Asset.png");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: right;
  }
}

@media (max-width: 849px) {
  .menus ul {
    flex-wrap: wrap;
  }
  .home-title {
    width: 100%;
  }
  .home-main-desc {
    width: 75%;
  }
}

@media (max-width: 768px) {
  .hero-box {
    background-size: contain;
    background-position: center;
  }
}

@media (max-width: 480px) {
  .nav-contents {
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0;
    align-items: center;
  }
  .menus ul {
    display: block;
  }
  .menus li {
    padding-top: 20px;
  }
  .home-title {
    font-size: 30px;
  }
  .home-main-desc {
    width: 100%;
  }
}
